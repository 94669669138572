import { useEffect, useState, useRef } from 'react'
import { useAnimation } from '../../hooks/useAnimation'

interface TypingAnimationProps {
  htmlContent: string
  id: string
  isError: boolean
  setFinishMessage: (value: boolean) => void
}

const TypingAnimation = ({ htmlContent, id, isError, setFinishMessage }: TypingAnimationProps) => {
  const { animationState, setAnimationState } = useAnimation()
  const savedState = animationState[id] || { visibleContent: '', index: 0 }

  const [visibleContent, setVisibleContent] = useState(savedState.visibleContent)
  const [index, setIndex] = useState(savedState.index)
  const [startAnimation, setStartAnimation] = useState(false)
  const animationCompleteRef = useRef(false)

  useEffect(() => {
    // Este efecto maneja el retraso inicial antes de empezar la animación
    const startTimer = setTimeout(() => {
      setStartAnimation(true)
    }, 200)

    return () => clearTimeout(startTimer)
  }, [])

  useEffect(() => {
    if (startAnimation) {
      if (index < htmlContent.length) {
        const timer = setTimeout(() => {
          setFinishMessage(false)
          const newVisibleContent = visibleContent + htmlContent.charAt(index)
          setVisibleContent(newVisibleContent)
          setIndex(index + 1)
          setAnimationState(id, { visibleContent: newVisibleContent, index: index + 1 })
        }, 13)
        return () => clearTimeout(timer)
      } else if (!animationCompleteRef.current) {
        // Call the setFinishMessage prop after a delay
        animationCompleteRef.current = true
        setTimeout(() => {
          setFinishMessage(true)
        }, 200) // Small delay before notifying
      }
    }
  }, [index, htmlContent, startAnimation, visibleContent, setAnimationState, id, setFinishMessage])

  return (
    <div
      className={`text-left rounded-primary ${isError ? 'text-red-600 dark:text-red-600' : ''}`}
      id={`message_content_${id}`}
      dangerouslySetInnerHTML={{ __html: visibleContent }}
    />
  )
}

export default TypingAnimation
