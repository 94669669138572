import { ReactElement, useEffect, useRef, useState } from 'react'
import fedeIco from '../../assets/icons/fedefarma-cross.png'
import { formatDateTime, formatJsonToHtml } from '../../shared/common'
import { Localization, MessageType, type IMessage } from '../../shared/interfaces'
import ThumbDownIcon from '../Icons/ThumbDownIcon'
import ThumbUpIcon from '../Icons/ThumbUpIcon'
import TypingAnimation from '../TypingAnimation/TypingAnimation'
import { useTranslation } from 'react-i18next'

const Message = ({
  content,
  initials,
  localeText,
  fullname,
  isLastMessage,
  blocked,
  handleRating,
  setStartMessage,
  setFinishMessage,
  finishMessage,
}: {
  content: IMessage
  startMessage: boolean
  lastMessageId?: number
  isLastMessage: boolean
  setStartMessage: (value: boolean) => void
  conversationMessages: IMessage[]
  initials: string
  fullname: string
  localeText: Localization | undefined
  blocked?: boolean
  handleRating: (message: IMessage) => void
  setFinishMessage: (value: boolean) => void
  finishMessage: boolean
}): ReactElement => {
  const { i18n } = useTranslation()
  const language = i18n.language

  const { message, type, rating, created_at } = content
  const dateRef = useRef<HTMLDivElement | null>(null)

  const displayText = formatJsonToHtml(message)
  const [selectedRating, setSelectedRating] = useState<null | 'LIKE' | 'DISLIKE'>(rating)
  const [showActionsAnswer, setShowActionsAnswer] = useState<boolean>(false)
  const [isBlocked, setIsBlocked] = useState<boolean>(blocked || false)

  const onClickRating = (value: null | 'LIKE' | 'DISLIKE') => {
    setSelectedRating(value === selectedRating ? null : value)
    handleRating({
      ...content,
      rating: value,
    } as IMessage)
  }

  const getMessageTypeClass = (type: MessageType): string => {
    switch (type) {
      case MessageType.QUESTION:
        return 'bg-primary'
      case MessageType.ANSWER:
        return 'border-primary border-opacity-60 border-2'
      default:
        return 'bg-red-400'
    }
  }

  const getMessageTypeContent = (type: MessageType, initials?: string): React.ReactNode => {
    switch (type) {
      case MessageType.QUESTION:
        return (
          <span className='tracking-wider pl-[1px] flex items-center justify-center rounded-full text-[15px] text-white cursor-default uppercase'>
            {initials}
          </span>
        )
      case MessageType.ANSWER:
        return <img src={fedeIco} className='flex items-center justify-center p-[3px] rounded-full' alt='Fede Icon' />
      default:
        return (
          <span className='flex items-center justify-center text-md cursor-default bg-white rounded-full w-[30px] h-[30px]'>
            <span className='flex items-center text-red-600 justify-center text-lg font-bold'>!</span>
          </span>
        )
    }
  }

  const getMessageTypeFullName = (type: MessageType, fullname?: string): string => {
    return type === MessageType.QUESTION ? fullname || '' : 'MIA'
  }

  useEffect(() => {
    if (content.isNew && type === MessageType.ANSWER) {
      setStartMessage(true)
    }
  }, [content])

  // crear un delay de 500ms para blocked
  useEffect(() => {
    if (blocked) {
      const timer = setTimeout(() => {
        setIsBlocked(true)
      }, 2000)
      return () => clearTimeout(timer)
    } else {
      setIsBlocked(false)
    }
  }, [blocked])

  const removeInitialQuestion = (text: string, language: string): string => {
    if (language === 'ca' && text.startsWith('¿')) {
      return text.slice(1).trim()
    }
    return text
  }

  return (
    <div
      className={`flex relative pb-2 w-full items-center font-primary`}
      onMouseEnter={() => {
        if (dateRef.current) {
          dateRef.current.classList.add('dark:invert')
          dateRef.current.classList.add('text-gray-700')
        }
      }}
      onMouseLeave={() => {
        if (dateRef.current) {
          dateRef.current.classList.remove('dark:invert')
          dateRef.current.classList.remove('text-gray-700')
        }
      }}
    >
      <div
        onMouseEnter={() => setShowActionsAnswer(true)}
        onMouseLeave={() => setShowActionsAnswer(false)}
        className='dark:text-white text-left min-w-[60%] xl:w-[62.5%] px-5 xl:px-0 w-[85%] text-sm md:gap-4 gap-1 m-auto relative'
      >
        <div className='w-full relative flex justify-start'>
          <div className='flex absolute sm:-left-[44px] -left-[20px] top-0 w-max items-center text-left text-xs transition-color duration-200 cursor-default text-gray-700/50'>
            <span
              className={`flex items-center justify-center cursor-default mr-2 rounded-full overflow-hidden w-[36px] h-[36px] ${getMessageTypeClass(
                type,
              )}`}
            >
              {getMessageTypeContent(type, initials)}
            </span>
            <div className='-mt-2'>
              <p className='text-[14px] leading-6 mt-2 font-bold text-[#0D0D0D] capitalize'>
                {getMessageTypeFullName(type, fullname)}
              </p>
              <p className='text-[12px] leading-6 -mt-2 font-semibold text-slate-30'>
                {formatDateTime(created_at, localeText)}
              </p>
            </div>
          </div>
        </div>

        <div className={`flex justify-start mt-[50px] overflow-y-hidden pl-5 sm:pl-0 overflow-x-auto scrollbar-x`}>
          <div className='flex flex-col gap-2 text-wrap'>
            {content && !content.isNew ? (
              // Caso para contenido no nuevo - Mostrar normalmente
              <div
                className={`text-left rounded-primary ${
                  type === MessageType.ERROR ? 'text-red-600 dark:text-red-600' : ''
                }`}
                id={`message_content_${content.id}`}
                dangerouslySetInnerHTML={{
                  __html: type === MessageType.QUESTION ? removeInitialQuestion(displayText, language) : displayText,
                }}
              />
            ) : (
              // Caso para contenido nuevo
              <div className=''>
                {content.isNew && type === MessageType.ANSWER ? (
                  // Solo para mensajes de tipo ANSWER que son nuevos
                  <TypingAnimation
                    setFinishMessage={setFinishMessage}
                    htmlContent={displayText}
                    id={`content_${content.id}`}
                    isError={false}
                  />
                ) : (
                  // Para mensajes de tipo QUESTION que son nuevos o cualquier otro caso que no sea ANSWER nuevo
                  <div
                    className={`text-left rounded-primary ${
                      type === MessageType.ERROR ? 'text-red-600 dark:text-red-600' : ''
                    }`}
                    id={`message_content_${content.id}`}
                    dangerouslySetInnerHTML={{
                      __html:
                        type === MessageType.QUESTION ? removeInitialQuestion(displayText, language) : displayText,
                    }}
                  />
                )}
              </div>
            )}
            <div className='h-8 p-1'>
              {type === MessageType.ANSWER &&
                ((showActionsAnswer && !isLastMessage) || (isLastMessage && !isBlocked && finishMessage)) && (
                  <div className='flex w-full justify-start gap-2'>
                    <ThumbUpIcon
                      selected={selectedRating === 'LIKE'}
                      onClick={() => {
                        selectedRating === 'LIKE' ? onClickRating(null) : onClickRating('LIKE')
                      }}
                    />
                    <ThumbDownIcon
                      selected={selectedRating === 'DISLIKE'}
                      onClick={() => {
                        selectedRating === 'DISLIKE' ? onClickRating(null) : onClickRating('DISLIKE')
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message
