import { t } from 'i18next'
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import fedeIco from '../../assets/icons/fedefarma-cross.png'
import UserContext from '../../hooks/context/UserContext'
import { listen } from '../../hooks/useSocket'
import { Conversation, ConversationAction, IMessage, Localization } from '../../shared/interfaces'
import Landing from '../Layout/Landing.component'
import Message from './Message'

interface Props {
  conversationMessages: IMessage[]
  showPrompts: boolean
  dispatch: React.Dispatch<{
    type: ConversationAction
    payload: any // eslint-disable-line
  }>
  localeText: Localization | undefined
  selectedConversation: Conversation | undefined
  handleRating: (message: IMessage) => void
  handlePromptClick: (prompt: string) => void
  loading: boolean
  clickPrompt: boolean
  setClickPrompt: (value: boolean) => void
  startMessage: boolean
  setStartMessage: (value: boolean) => void
  chatBoxRef: React.RefObject<HTMLDivElement>
}

let messageid = -1

const ChatRoom = (props: Props): ReactElement => {
  const {
    conversationMessages,
    localeText,
    selectedConversation,
    handlePromptClick,
    dispatch,
    setStartMessage,
    startMessage,
    showPrompts,
    handleRating,
    loading,
    clickPrompt,
    setClickPrompt,
  } = props
  const [finishMessage, setFinishMessage] = useState(false)
  const { user } = useContext(UserContext)
  const chatBoxRef = useRef<HTMLDivElement | null>(null)
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const conversationId = useRef<number | undefined>(undefined)
  const callBack = useCallback(
    (data: IMessage | Conversation) => {
      if (selectedConversation?.id === conversationId.current) {
        clickPrompt && setClickPrompt(false)
        if (messageid === data.id) {
          dispatch({ type: ConversationAction.UPDATE_MESSAGE, payload: data })
          setStartMessage(false)
          return
        }

        messageid = data.id
        dispatch({ type: ConversationAction.TYPING, payload: false })
        dispatch({ type: ConversationAction.SEND_MESSAGE, payload: data })
      }
    },
    [dispatch, selectedConversation?.id, setClickPrompt, setStartMessage],
  )

  useEffect(() => {
    if (selectedConversation && selectedConversation?.id !== conversationId.current) {
      conversationId.current = selectedConversation?.id
      listen(callBack, `conversations.${selectedConversation.id}`, '.message.sent')
    }
  }, [selectedConversation, callBack])

  const initals = useMemo(() => {
    if (user && user.first_name && user.last_name) {
      return (user.first_name[0] + user.last_name[0]).toUpperCase()
    } else if (user && user.first_name) {
      return user.first_name[0].toUpperCase()
    }
    return ''
  }, [user])

  const isResponseLoading = useMemo(() => {
    if (selectedConversation) {
      if (selectedConversation?.blocked && !startMessage) {
        return true
      } else {
        return false
      }
    }
    return false
  }, [selectedConversation, startMessage])

  // evitar la duplicidad de items dentro de conversationMessages mediando su id
  const conversationCleaned = useMemo(() => {
    const ids = new Set()
    return conversationMessages?.filter((message) => {
      if (ids.has(message.id)) {
        return false
      }
      ids.add(message.id)
      if (message.type === 'ERROR') {
        message.message = t(
          localeText?.['mia_error_response'] ?? 'He sufrido un error, por favor intentalo de nuevo mas tarde.',
        )
      }
      return true
    })
  }, [conversationMessages, localeText])

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      requestAnimationFrame(() => {
        chatBoxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [conversationCleaned, chatBoxRef.current, isResponseLoading, finishMessage, startMessage])

  return (
    <div className='w-full  pb-5 relative' ref={chatBoxRef}>
      {conversationCleaned.length > 0 ? (
        conversationCleaned.map((content: IMessage, i: number) => (
          <div
            id={`message_${content.id}`}
            className={messageid === content.id ? 'message-list' : ''}
            style={messageid === content.id ? { animation: 'fadeIn 200ms ease-out 50ms forwards' } : {}}
            key={`${content.id}_message_${content.type}_${i}`}
            ref={i === conversationCleaned.length - 1 ? lastMessageRef : null}
          >
            <Message
              finishMessage={finishMessage}
              startMessage={startMessage}
              setFinishMessage={setFinishMessage}
              setStartMessage={setStartMessage}
              conversationMessages={conversationCleaned}
              content={content}
              lastMessageId={messageid}
              isLastMessage={
                i === conversationCleaned.length - 1 &&
                conversationCleaned[conversationCleaned.length - 1].id === content.id
              }
              initials={initals}
              fullname={user?.first_name + ' ' + user?.last_name}
              localeText={localeText}
              handleRating={handleRating}
              blocked={selectedConversation?.blocked}
            />
          </div>
        ))
      ) : showPrompts ? (
        <div className='flex flex-col items-center absolute bottom-5 fadeIn  w-full '>
          <Landing
            setStartMessage={setStartMessage}
            isConversation={false}
            localeText={localeText}
            loading={clickPrompt || loading}
            handlePromptClick={handlePromptClick}
          />
        </div>
      ) : null}
      {!showPrompts && isResponseLoading && (
        <div
          className='flex flex-col min-w-[60%] h-[50px] xl:w-[62.5%] w-[85%] m-auto px-5 xl:px-0 dark:bg-gray-300 opacity-0'
          style={{ animation: 'fadeIn 300ms ease-out 100ms forwards' }}
        >
          <div className='text-left w-full bg-red-200  relative  text-sm md:gap-4   '>
            <div className='w-full absolute top-0 sm:-left-[44px] -left-[20px]  flex gap-2 mb-2'>
              <span className='flex items-center justify-center cursor-default mr-2 border-1 rounded-full w-[36px] h-[36px]'>
                <img src={fedeIco} className='flex items-center justify-center h-7' />
              </span>
              <div className='flex flex-col'>
                <span className='flex items-center justify-left cursor-default capitalize w-[30px] h-[30px] text-md font-bold text-gray-700/50 ml-[-8px] mt-[-3px]'>
                  {localeText?.['Typing...']}
                </span>
                <div className='w-full pb-2 '>
                  <div className='loader'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatRoom
