import { useContext, useLayoutEffect, useState } from 'react'
import EditIcon from '../Icons/EditIcon'
import useConversation from '../../hooks/useConversation'
import { Conversation, ConversationAction, ConversationState } from '../../shared/interfaces'
import { LanguageContext } from '../../hooks/context/LanguageContext'

interface Props {
  onCloseSidebar: () => void
  state: ConversationState
  dispatch: React.Dispatch<{
    type: string
    payload: any // eslint-disable-line
  }>
}

const MobileBar = ({ state, dispatch }: Props) => {
  const { updateConversation } = useConversation()
  const { localeText } = useContext(LanguageContext)
  const [edit, setEdit] = useState<boolean>(false)
  const [newTitle, setNewTitle] = useState<string>('')

  useLayoutEffect(() => {
    if (state.selectedConversation?.title) {
      setNewTitle(state.selectedConversation?.title)
    } else {
      setNewTitle(localeText?.['New conversation'] || 'New conversation')
    }
  }, [state.selectedConversation?.title])
  const existTitle = state.selectedConversation?.title || newTitle
  return (
    <div className='flex flex-row justify-between items-center h-full bg-white px-4'>
      <div className='text-black text-sm px-8 truncate w-full text-center'>
        {edit ? (
          <input
            className='bg-transparent w-full text-center dark:bg-transparent dark:text-white text-black selection:bg-primary/30 focus:outline-none focus:ring-1 focus:ring-primary/30 focus:bg-transparent'
            type='text'
            defaultValue={state.selectedConversation?.title}
            autoFocus
            value={newTitle || state.selectedConversation?.title}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              e.stopPropagation()
              setEdit(false)
            }}
            onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNewTitle(e.target.value)
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                setEdit(false)
                dispatch({ type: ConversationAction.UPDATE, payload: state.selectedConversation })
                updateConversation({
                  id: state.selectedConversation?.id,
                  title: e.currentTarget.value,
                } as Conversation)
              }
            }}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              //select all text
              e.target.select()
            }}
          />
        ) : (
          newTitle || state.selectedConversation?.title
        )}
      </div>

      {existTitle && (
        <EditIcon
          onClick={(e: React.MouseEvent<SVGElement>) => {
            e.stopPropagation()
            if (state.selectedConversation?.blocked || !state.selectedConversation) {
              return
            }
            setEdit(true)
          }}
        />
      )}
    </div>
  )
}

export default MobileBar
