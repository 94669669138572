import { Route, Routes } from 'react-router-dom'
import NoAuth from '../pages/Auth/NoAuth/NoAuth'
import Home from '../pages/Home/Home'
import AutoLogin from './AutoLogin'
import ProtectedRoutes from './ProtectedRoutes'
// import TermsAndConditions from '../pages/Legal/TermsAndConditions'
// import PrivacyPolicy from '../pages/Legal/PrivacyPolicy'

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/auth/:token' element={<AutoLogin />} />

      <Route element={<ProtectedRoutes />}>
        <Route path='/' element={<Home />} />
      </Route>

      <Route path='/no-auth' element={<NoAuth />} />

      {/* No subir a pro */}
      {/* <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} /> */}
      <Route path='*' element={<ProtectedRoutes />} />
    </Routes>
  )
}

export default AppRoutes
