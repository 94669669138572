import { createContext, useState } from 'react'

interface AnimationState {
  [key: string]: {
    visibleContent: string
    index: number
  }
}

interface AnimationContextData {
  animationState: AnimationState
  setAnimationState: (id: string, state: { visibleContent: string; index: number }) => void
}

interface AnimationProviderProps {
  children: React.ReactNode
}

const AnimationContext = createContext<AnimationContextData>({} as AnimationContextData)

export function AnimationProvider({ children }: AnimationProviderProps) {
  const [animationState, setAnimationStateInternal] = useState<AnimationState>({})

  const setAnimationState = (id: string, state: { visibleContent: string; index: number }) => {
    setAnimationStateInternal((prev) => ({
      ...prev,
      [id]: state,
    }))
  }

  return <AnimationContext.Provider value={{ animationState, setAnimationState }}>{children}</AnimationContext.Provider>
}

export default AnimationContext
