import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../hooks/context/LanguageContext'
import useConversation from '../../hooks/useConversation'
import { Conversation, ConversationAction, IMessage, MessageType } from '../../shared/interfaces'
import { summarizeMessage } from '../../utils/summarizeMessage'
import ModalError from '../ModalError'
interface Props {
  calculateHeight: (element: HTMLTextAreaElement) => void
  dispatch: React.Dispatch<{
    type: string
    payload: any // eslint-disable-line
  }>
  inputRef: React.MutableRefObject<HTMLTextAreaElement | null>
  containerInputRef: React.MutableRefObject<HTMLDivElement | null>
  selectedConversation: Conversation | undefined
  conversationMessages: IMessage[]
  loading: boolean
  isBlockChat: () => boolean
  clickPrompt: boolean
  setClickPrompt: (value: boolean) => void
  startMessage: boolean
  setStartMessage: (value: boolean) => void
}

const ChatInput = (props: Props) => {
  const { localeText } = useContext(LanguageContext)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<{ message: string; title: string } | undefined>(undefined)
  const {
    calculateHeight,
    dispatch,
    inputRef,
    containerInputRef,
    selectedConversation,
    conversationMessages,
    loading,
    isBlockChat,
    setClickPrompt,
    setStartMessage,
  } = props
  const { updateConversation, createNewConversation, sendMessage /*stopGenerating*/ } = useConversation(
    {
      conversationId: selectedConversation?.id,
    },
    dispatch,
  )

  const isMobile = window.innerWidth < 768

  const sendNewMessage = async (value: string) => {
    setStartMessage(false)
    if (
      !(
        value.trim().length > 0 &&
        ((selectedConversation && !selectedConversation.blocked) || selectedConversation === undefined) &&
        conversationMessages &&
        conversationMessages[conversationMessages.length - 1]?.type !== MessageType.QUESTION
      )
    )
      return

    if (isBlockChat()) return
    setClickPrompt(true)

    const input = inputRef.current
    if (input) {
      input.value = ''
    }
    // volver los styles al icono y al borde
    inputRef?.current?.style.setProperty('border-bottom', '1px solid #d1d5db')
    const icon = document.getElementById('send-message-icon')
    if (icon) {
      const svg = icon.getElementsByTagName('svg')
      if (svg.length > 0) {
        svg[0].style.fill = '#d1d5db'
      }
    }
    let id = selectedConversation?.id
    if (!selectedConversation) {
      id = await createNewConversation(value)
    }

    if (id) {
      const message = value.replace(/\n/g, '<br />')
      if (conversationMessages.length === 0) {
        await updateConversation({
          id,
          title: summarizeMessage(message),
        } as Conversation)
      }
      await sendMessage(message, id).then((res) => {
        if (res) {
          setDisabled(true)
          calculateHeight(input as HTMLTextAreaElement)
          setClickPrompt(false)
          dispatch({ type: ConversationAction.TYPING, payload: true })
          if (!res.success) {
            if (res.status === 403) {
              setShowModal({
                message: t(localeText?.['excceded_messages'] ?? 'excceded_messages'),
                title: 'Message blocked',
              })
            } else {
              setShowModal({
                message: 'No se ha podido enviar tú mensaje',
                title: 'Error',
              })
            }
          }
        }
      })
    }
    if (isMobile) {
      setTimeout(() => {
        inputRef.current?.blur()
      }, 500)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value.length === 0) {
        inputRef.current.style.borderBottom = '1px solid #66686b'
        const icon = document.getElementById('send-message-icon')
        if (icon) {
          const svg = icon.getElementsByTagName('svg')
          if (svg.length > 0) {
            svg[0].style.fill = '#d1d5db'
          }
        }
      } else {
        inputRef.current.style.borderBottom = '1px solid #af1685'
        const icon = document.getElementById('send-message-icon')
        if (icon) {
          const svg = icon.getElementsByTagName('svg')
          if (svg.length > 0) {
            svg[0].style.fill = '#af1685'
          }
        }
      }
    }
  }, [inputRef.current?.value])

  useEffect(() => {
    if (inputRef?.current) {
      const isMobile = window.innerWidth < 768
      if (!isMobile) {
        inputRef.current.focus()
      }
    }
  }, [selectedConversation, loading])

  return (
    <div className=' relative w-[85%]  min-h-[112px]  xl:w-[62.5%] m-auto' ref={containerInputRef}>
      {showModal && <ModalError message={showModal.message} onClose={() => setShowModal(undefined)} />}
      <div
        className='w-100 rounded-primary  px-5 xl:px-0'
        style={{
          position: 'absolute',
          bottom: '1rem',
          left: 0,
          right: 0,
          margin: '0 auto',
        }}
      >
        <div className='flex flex-col  w-full flex-grow relative dark:border-gray-900/60 dark:text-white rounded-primary  dark:bg-gray-700 pb-1 h-auto  justify-between'>
          <div className='relative'>
            <textarea
              className='text-[14px] pb-2   outline-none align-text-bottom  max-h-[104px]   md:max-h-[120px] w-full resize-none bg-transparent  pr-10 dark:placeholder-white/50  overflow-hidden'
              ref={inputRef}
              onInput={(e: React.FormEvent<HTMLTextAreaElement>) => {
                const icon = document.getElementById('send-message-icon')
                if (icon) {
                  const svg = icon.getElementsByTagName('svg')
                  if (svg.length > 0) {
                    svg[0].style.fill = '#af1685'
                  }
                }

                if (disabled) {
                  if (icon) {
                    const svg = icon.getElementsByTagName('svg')
                    if (svg.length > 0) {
                      svg[0].style.fill = '#d1d5db'
                    }
                  }
                }
                calculateHeight(e.currentTarget)
              }}
              placeholder={localeText?.['Type a message...']}
              style={{
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                scrollBehavior: 'smooth',
              }}
              onChangeCapture={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (e.target.value.length > 0) {
                  setDisabled(false)
                } else {
                  setDisabled(true)
                }
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                // Si la tecla presionada es 'Enter' y no se presiona la tecla 'Shift'
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  sendNewMessage(e.currentTarget.value)
                }
              }}
              disabled={loading}
              autoFocus={isMobile ? false : true}
            />
            <button
              id='send-message-icon'
              data-control='send-message'
              className='absolute right-2  duration-500 flex items-cener justify-center bottom-2  transition-all'
              onClick={() => {
                sendNewMessage(inputRef.current?.value ?? '')
              }}
              disabled={disabled || selectedConversation?.blocked}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                fill='#d1d5db'
                className='hover:fill-primary/50 transition-all duration-200 cursor-pointer'
              >
                <g data-name='Layer 2'>
                  <g data-name='arrow-upward'>
                    <rect width='24' height='24' transform='rotate(180 12 12)' opacity='0' />
                    <path d='M5.23 10.64a1 1 0 0 0 1.41.13L11 7.14V19a1 1 0 0 0 2 0V7.14l4.36 3.63a1 1 0 1 0 1.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 0 0-.72 0l-.13.07-.15.09-6 5a1 1 0 0 0-.13 1.41z' />
                  </g>
                </g>
              </svg>
            </button>
          </div>
          <div className='relative overflow-hidden h-[2px] w-full top-[-6px]'>
            <div className='absolute left-0 bottom-0 top-0 bg-[#d1d5db] w-full'></div>
            <div
              className={`absolute left-0 bottom-0 top-0 bg-[#af1685] ${
                loading ? 'progress' : disabled ? 'hidden' : ''
              }  w-full`}
            ></div>
          </div>
          <p className='text-xs text-center text-gray-500'>{localeText?.['virtual_assistant_message']}</p>
        </div>
      </div>
    </div>
  )
}

export default ChatInput
