import { Localization } from '../../shared/interfaces'
import { useTranslation } from 'react-i18next'

interface LandingProps {
  localeText?: Localization | undefined
  isConversation?: boolean
  loading?: boolean
  setStartMessage: (value: boolean) => void
  handlePromptClick: (prompt: string) => void
}

const Landing = ({ localeText, handlePromptClick, isConversation = false, loading, setStartMessage }: LandingProps) => {
  // averiguar el idioma del usuario
  const { i18n } = useTranslation()
  const language = i18n.language
  const removeInitialQuestion = (text: string): string => {
    if (language === 'ca' && text.startsWith('¿')) {
      return text.slice(1).trim()
    }
    return text
  }

  return (
    <div className=' flex w-full h-full flex-col gap-5 sm:gap-40 overflow-hidden'>
      <div className='flex  flex-col items-center justify-center h-full w-full gap-6 mt-[90px] sm:mt-0'>
        <div className='text-3xl text-gray-400 dark:text-gray-500'>
          <img src='../assets/images/MIA.png' className='h-16' alt='logo' />
        </div>
        <div className='text-2xl text-gray-400 dark:text-gray-500'>{localeText?.['How can I help you today?']}</div>
      </div>

      {!isConversation && (
        <div className='flex flex-col relative items-center justify-center md:justify-end h-full w-full m-auto gap-6'>
          <div className='grid grid-cols-1 px-5 xl:px-0 md:grid-cols-2 xl:w-[62.5%] w-[85%] rounded-primary sm:gap-4 gap-2'>
            {Array.from({ length: 4 }, (_, index) => (
              <button
                key={`prompt_row_${index}`}
                className={`flex  flex-col px-2 py-2 items-center justify-center text-lg text-gray-400 dark:text-gray-500 border border-soft-gray rounded-xl
                ${loading ? 'bg-slate-100' : 'hover:bg-slate-100'}`}
                onClick={() => {
                  setStartMessage(false)
                  handlePromptClick(localeText?.[`Prompt ${index + 1} text`] ?? '')
                }}
                disabled={loading}
              >
                <span
                  className={`w-full text-left px-2 font-bold text-primary text-sm uppercase md:text-lg
                ${loading ? 'text-slate-400' : 'text-primary'}
                `}
                >
                  {localeText?.[`Prompt ${index + 1} title`]}
                </span>
                <p className='w-full text-left text-xs md:text-sm px-2'>
                  {removeInitialQuestion(localeText?.[`Prompt ${index + 1} text`] ?? '')}
                </p>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Landing
