import { t } from 'i18next'
import React, { useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import fedeLogo from '../../assets/fedefarma-logo.png'
import { LanguageContext } from '../../hooks/context/LanguageContext'
import UserContext from '../../hooks/context/UserContext'
import ConversationReducer from '../../hooks/reducers/ConversationReducer'
import useConversation from '../../hooks/useConversation'
import { Conversation, IMessage } from '../../shared/interfaces'
import { summarizeMessage } from '../../utils/summarizeMessage'
import ChatInput from '../Chat/ChatInput'
import ChatRoom from '../Chat/ChatRoom'
import ModalError from '../ModalError'
import SideBar from '../SideBar/SideBar'
// import WelcomeModal from '../WelcomeModal'
import MobileBar from './MobileBar.component'
import OpenSideBarBtn from './OpenSideBarBtn.component'
// import WelcomeModal from '../WelcomeModal'

const PageLayout = () => {
  const { localeText } = useContext(LanguageContext)
  const { user, sessionId } = useContext(UserContext)
  const [showOpenMenu, setShowOpenMenu] = useState(false)
  const [startMessage, setStartMessage] = useState(false)
  const [blockChat, setBlockChat] = useState(false)
  const [showModal, setShowModal] = useState<{ message: string; title: string } | undefined>(undefined)
  // const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false)
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 768)

  const [state, dispatch] = useReducer(ConversationReducer, {
    conversationList: [],
    conversationMessages: [],
    selectedConversation: undefined,
    showPrompts: true,
  })
  const { createNewConversation, updateConversation, updateRating, sendMessage, loading } = useConversation(
    {
      conversationId: state.selectedConversation?.id,
    },
    dispatch,
  )
  const chatBoxRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  const containerInputRef = React.useRef<HTMLDivElement>(null)
  const sidebarRef = React.useRef<HTMLDivElement>(null)
  const [clickPrompt, setClickPrompt] = useState<boolean>(false)

  // useEffect(() => {
  //   if (user) {
  //     if (user.contracts_accepted) {
  //       setShowWelcomeModal(false)
  //     } else {
  //       setShowWelcomeModal(true)
  //     }
  //   }
  // }, [user])

  useEffect(() => {
    // detectar window size
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 768)
      if (window.innerWidth <= 768) {
        onCloseSidebar()
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      calculateHeight(inputRef.current)
    }
  }, [])

  const calculateHeight = (element: HTMLTextAreaElement) => {
    // reset height to get actual content size
    element.style.height = '0'

    // set textarea height based on content size
    element.style.height = `${element.scrollHeight}px`

    // add max height and vertical scroll if needed
    const shouldAddScroll = element.scrollHeight > 150 || (window.innerWidth <= 800 && element.scrollHeight >= 120)
    element.style.overflowY = shouldAddScroll ? 'auto' : 'hidden'
    element.style.height = shouldAddScroll ? '150px' : `${element.scrollHeight}px`

    // Scroll to bottom if added scroll
    if (shouldAddScroll) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      })
    }
    if (!isMobileScreen) {
      element.focus()
    }
    if (!containerInputRef.current) return
    // adjusts container height to fit input content, ensuring visibility without scrolling
    containerInputRef.current.style.height = `calc(${element.style.height} + 56px)`
  }

  const onCloseSidebar = () => {
    const sidebar = sidebarRef.current
    const chatBox = chatBoxRef.current
    if (sidebar) {
      if (!showOpenMenu) {
        sidebar.style.width = '70px'
      } else {
        sidebar.removeAttribute('style')
      }
    }
    if (chatBox) {
      chatBox.style.width = showOpenMenu ? '100%' : 'calc(100% - 0px)'
    }
    setShowOpenMenu(!showOpenMenu)
  }

  const isBlockChat = () => {
    if (blockChat) return true

    setBlockChat(true)

    setTimeout(() => {
      setBlockChat(false)
    }, 1000)

    return false
  }

  const handlePromptClick = async (message: string) => {
    if (isBlockChat()) return
    setClickPrompt(true)
    let id = state.selectedConversation?.id
    if (!state.selectedConversation) {
      id = await createNewConversation(message)
    }

    if (id) {
      if (state.conversationMessages.length === 0) {
        await updateConversation({
          id,
          title: summarizeMessage(message),
        } as Conversation)
      }
      await sendMessage(message, id).then((res) => {
        if (res) {
          if (res.status === 403) {
            setShowModal({
              message: t(localeText?.['excceded_messages'] ?? 'excceded_messages'),
              title: 'Message blocked',
            })
          }
          setClickPrompt(false)
        }
      })
    }
  }

  const handleRating = async (message: IMessage) => {
    await updateRating(message)
  }

  useLayoutEffect(() => {
    if (isMobileScreen && !showOpenMenu) {
      onCloseSidebar()
    }

    if (inputRef.current) {
      calculateHeight(inputRef.current)
    }
  }, [state.selectedConversation])

  return (
    <div className='flex  h-full  overflow-hidden  transition-colors duration-200'>
      {showModal && <ModalError message={showModal.message} onClose={() => setShowModal(undefined)} />}
      {/* {showWelcomeModal && (
        <WelcomeModal acceptContracts={acceptContracts} onClose={() => setShowWelcomeModal(false)} />
      )} */}
      <div className='sm:hidden absolute top-0 left-0 w-full z-10 h-10'>
        <MobileBar onCloseSidebar={onCloseSidebar} state={state} dispatch={dispatch} />
      </div>
      <div
        className={` z-10   dark:bg-dark-gray  ${isMobileScreen ? 'fixed top-[50px] pb-[50px]' : 'relative'}  ${
          isMobileScreen && showOpenMenu ? '!w-0' : ''
        } h-full  transition-all    duration-500 w-2/4 sm:w-2/5 lg:w-2/5 xl:w-1/4 2xl:w-1/5 `}
        ref={sidebarRef}
      >
        <div
          className={`absolute bottom-32 z-50 ${
            showOpenMenu && isMobileScreen
              ? '-left-6'
              : showOpenMenu
              ? 'left-1/2  transform -translate-x-1/2'
              : '-right-5'
          }`}
        >
          <OpenSideBarBtn onCloseSidebar={onCloseSidebar} showOpenMenu={showOpenMenu} isMobile={isMobileScreen} />
        </div>
        <div className={`relative  h-full   ${isMobileScreen && showOpenMenu ? 'w-0' : 'w-full'} overflow-hidden`}>
          <SideBar
            inputRef={inputRef}
            conversationList={state.conversationList}
            dispatch={dispatch}
            selectedConversation={state.selectedConversation}
            userId={user!.id}
            sessionId={sessionId!}
            showOpenMenu={showOpenMenu}
            isMobile={isMobileScreen}
          />
        </div>
      </div>
      <div
        className='bg-white dark:bg-gray-900 h-full duration-200 w-full relative overflow-hidden flex flex-col'
        ref={chatBoxRef}
      >
        {/* Chat area */}
        <div
          id='chat-area'
          className={`w-full ${
            isMobileScreen ? 'pt-16' : 'pt-[43px]'
          } h-full   overflow-x-hidden relative flex flex-col items-center ${
            state.conversationMessages.length === 0 ? 'justify-end' : 'justify-start'
          } overflow-y-auto`}
        >
          <ChatRoom
            chatBoxRef={chatBoxRef}
            startMessage={startMessage}
            setStartMessage={setStartMessage}
            showPrompts={state.showPrompts}
            conversationMessages={state.conversationMessages}
            dispatch={dispatch}
            selectedConversation={state.selectedConversation}
            localeText={localeText}
            handlePromptClick={handlePromptClick}
            handleRating={handleRating}
            loading={loading}
            clickPrompt={clickPrompt}
            setClickPrompt={setClickPrompt}
          />
        </div>

        <ChatInput
          calculateHeight={calculateHeight}
          startMessage={startMessage}
          setStartMessage={setStartMessage}
          dispatch={dispatch}
          inputRef={inputRef}
          containerInputRef={containerInputRef}
          conversationMessages={state.conversationMessages}
          selectedConversation={state.selectedConversation}
          loading={loading}
          isBlockChat={isBlockChat}
          clickPrompt={clickPrompt}
          setClickPrompt={setClickPrompt}
        />
      </div>
      {/* <div className='text-3xl text-gray-400 dark:text-gray-500 w-full fixed bottom-5 left-5 lg:hidden opacity-40 z-[50]'>
        {!showOpenMenu && <img src={fedeLogo} className='h-8' alt='logo' />}
      </div> */}
      <div className=' xl:block  hidden text-3xl text-gray-400 dark:text-gray-500 w-full fixed bottom-5  xl:left-[calc(100dvw-130px)] opacity-40 z-[50]'>
        <img src={fedeLogo} className='h-8' alt='logo' />
      </div>
    </div>
  )
}

export default PageLayout
