import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './hooks/context/UserContext'
import AppRoutes from './routes/AppRoutes'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { AnimationProvider } from './hooks/context/AnimationContext'
// import useClarity from './hooks/useClarity'
// import CookieBanner from './components/CookieBanner/CookieBanner'

let onlyOnce = true
function App() {
  useEffect(() => {
    if (!onlyOnce || window.location.href.includes('localhost')) return
    onlyOnce = false
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: 'https://c62adab3caf72de3b8fcddcbbe8f8533@o4504179677790208.ingest.sentry.io/4506257796366336',

      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
        }),

        new Sentry.Replay(),
      ],

      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }, [])

  // useClarity()

  return (
    <div
      className='w-full h-full dark:bg-gray-800 dark:text-white'
      style={{
        height: 'calc(100vh - 48px)',
      }}
    >
      <UserProvider>
        <AnimationProvider>
          <BrowserRouter>
            <AppRoutes />
            {/* <CookieBanner /> */}
          </BrowserRouter>
        </AnimationProvider>
      </UserProvider>
    </div>
  )
}

export default App
